<template>
  <div>
    
    <b-row class="match-height">
      <b-col xs="4" lg="4" md="12" sm="12" class="mr-0">
          <goat-hero :goatHero="goatHero"/>
      </b-col>  
      <b-col xs="8" lg="8" md="12" sm="12" class="mr-0">
          <goat-profile :goatProfile="goatProfile" />
      </b-col>  
    </b-row>
    
    <table-raw-milk-history />
  </div>
</template>

<script>
import { 
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination, } from 'bootstrap-vue'

import GoatProfile from './GoatProfile.vue'
import GoatHero from '@/layouts/components/GoatHero.vue'
import TableRawMilkHistory from './TableRawMilkHistory.vue'

export default {  
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    GoatProfile,
    GoatHero,
    TableRawMilkHistory,
  },
  data() {
    return {
      goatHero: [],
      goatProfile: [],
      milkConditionOptions: [],
    }
  },
  created() {
    // get detail animals
    this.$http.get('/animals/' + this.$route.params.id + '/detail' ).then(response => {
      const data = response.data.data
      
      this.goatHero = data

      this.goatProfile = data
    })
  },
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
