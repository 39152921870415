<template>
  <b-sidebar
    id="add-new-raw-milk-sidebar"
    :visible="isAddNewRawMilkSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-raw-milk-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Catat Hasil Perah
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

          <!-- Goat Mother -->
          <validation-provider
          #default="validationContext"
          name="Kambing Betina"
          
        >
          <b-form-group
            label="Kambing Betina"
            label-for="animal_id"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="rawMilkData.animal_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="femaleGoatOptions"
              :clearable="false"
              input-id="animal_id"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- Volume Susu Mentah -->
          <validation-provider
            #default="validationContext"
            name="Volume"
            rules="required"
          >
            <b-form-group
              label="Volume"
              label-for="volume"
            >
              <b-form-input
                id="volume"
                v-model="rawMilkData.volume"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Goat Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Kondisi Susu -->
          <validation-provider
          #default="validationContext"
          name="Kondisi Susu">

          <b-form-group
            label="Kondisi Susu"
            label-for="milk_condition_id"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="rawMilkData.milk_condition_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="milkConditionOptions"
              :clearable="false"
              input-id="milk_condition_id"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- Tanggal Perah -->
          <validation-provider
            #default="validationContext"
            name="Tanggal Perah"
            rules="required"
          >
            <b-form-group
              label="Tanggal Perah"
              label-for="date_milked"
            >
              <b-form-datepicker
                id="date_milked"
                placeholder="Choose a date"
                v-model="rawMilkData.date_milked"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Waktu Perah -->
          <validation-provider
            #default="validationContext"
            name="Waktu Perah"
            rules="required"
          >
            <b-form-group
              label="Waktu Perah"
              label-for="time_milked"
            >
              <b-form-timepicker
                id="time_milked"
                placeholder="Choose a date"
                v-model="rawMilkData.time_milked"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deskripsi -->
          <validation-provider
          #default="validationContext"
          name="Deskripsi">

          <b-form-group
            label="Deskripsi"
            label-for="description"
            :state="getValidationState(validationContext)"
          >
            <b-form-input
              id="description"
              v-model="rawMilkData.description"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Deskripsi hasil perah"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRawMilkSidebarActive',
    event: 'update:is-add-new-raw-milk-sidebar-active',
  },
  props: {
    isAddNewRawMilkSidebarActive: {
      type: Boolean,
      required: true,
    },
    femaleGoatOptions: {
      type: Array,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      milkConditionOptions: [],
    }
  },
  setup(props, { emit }) {

    // use Toast
    const toast = useToast()
    // setup json post goat data
    const blankRawMilkData = {
      animal_id: null,
      volume: null,
      milk_condition_id: null,
      date_milked: '',
      time_milked: '',
      description: '',
    }

    //set default birth date is today
    const today = new Date();
    blankRawMilkData.birth_date = today;
    
    // define rawMilkData from JSON data to ref variable
    const rawMilkData = ref(JSON.parse(JSON.stringify(blankRawMilkData)))

    // when click Cancle button
    const resetRawMilkData = () => {
      rawMilkData.value = JSON.parse(JSON.stringify(blankRawMilkData))
    }

    // when click Add button
    const onSubmit = () => {
     
      store.dispatch('app-raw-milk/addRawMilk', rawMilkData.value).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mencatat hasil perah susu',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

      emit('refetch-data')
      emit('update:is-add-new-raw-milk-sidebar-active', false)
    })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetRawMilkData)

    return {
      rawMilkData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    // get available pens
    this.$http.get('/animals/goats/female').then(response => {
      const femaleGoats = response.data.data

      femaleGoats.forEach(pen => {
        this.penOptions.push(
          {
            label: pen.name, 
            value:  pen.id
          }
        )
      });
    })
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
