import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRawMilksList() {
  // Use toast
  const toast = useToast()

  const refRawMilkHistoryTable = ref(null)

  // Table Handlers
  const tableColumns = [
      { key: 'no', sortable: false}, 
      { key: 'volume', sortable: false}, 
      { key: 'kondisi_susu', sortable: false}, 
      { key: 'waktu_perah', sortable: false}, 
      { key: 'deskripsi', sortable: false}, 
      { key: 'aksi', sortable: false},
  ]

  const perPage = ref(10)
  const totalRawMilks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refRawMilkHistoryTable.value ? refRawMilkHistoryTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRawMilks.value,
    }
  })

  const refetchData = () => {
    refRawMilkHistoryTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const fetchRawMilkHistory = (ctx, callback) => {
    store
      .dispatch('app-raw-milks/fetchRawMilkHistory', {
        id: router.currentRoute.params.id
      })
      .then(response => {
        const { data } = response.data
        const { total } = response.data.data

        const goatRawMilkMapped = data.map(({
          id, 
          volume, 
          kondisi_susu, 
          tanggal_perah, 
          jam_perah, 
          waktu_perah, 
          deskripsi,
        }) => ({
          index: 0,
          id,
          volume,
          kondisi_susu,
          waktu_perah,
          deskripsi,
          tanggal_perah,
          jam_perah,
          })
        );

        callback(goatRawMilkMapped)
        totalRawMilks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal Mendapatkan data susu mentah',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchRawMilkHistory,
    tableColumns,
    perPage,
    currentPage,
    totalRawMilks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRawMilkHistoryTable,

    refetchData,

  }
}
