<template>
  <b-card
    no-body
    class="card-apply-job"
  >
    <b-card-body class="pb-0 mb-0">
      <b-row class="match-height mb-2">
        <b-col cols="5" class="pl-1">
            <h4>Profile</h4>
        </b-col>

        <b-col cols="7" class="pl-1" align-h="right">
          <p>Last Updated {{ goatProfile.last_updated }} </p>
        </b-col>
      </b-row>
  
      <b-row class="match-height">
        <b-col cols="5" class="pl-1">
          <b-row class="pl-1">
            <h5 class="mr-0">Status Genetik :</h5>
            <p class="ml-1">
              {{ goatProfile.genetic_status }}
            </p>
          </b-row>
        </b-col>
        <b-col cols="7" class="pl-1">
          <b-row class="pl-1">
            <h5 class="mr-0">Kandang :</h5>
            <p class="ml-1">
              {{ goatProfile.pen_name }}
            </p>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="5" class="pl-1">
          <b-row class="pl-1">
            <h5 class="mr-0">Jenis Kelamin :</h5>
            <p class="ml-1">
              {{ goatProfile.gender }}
            </p>
          </b-row>
        </b-col>
        <b-col cols="7" class="pl-1">
          <b-row class="pl-1">
            <h5 class="mr-0">Terakhir diperbaruhi :</h5>
            <p class="ml-1">
              {{ goatProfile.last_updated }}
            </p>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col cols="5" class="pl-1">
          <b-row class="pl-1">
            <h5 class="mr-0">Berat :</h5>
            <p class="pl-1">
              {{ goatProfile.current_weight }}
            </p>
          </b-row>
        </b-col>
        <b-col cols="7" class="pl-1">

        </b-col>
      </b-row>
      
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    goatProfile: {
      type: Array,
      default: () => [
        {
          animal_id: null,
          animal_name: '',
          age: '',
          animal_status: '',
          animal_status_out: '',
          animal_type: '',
          genetic_status: '',
          gender: '',
          current_weight: '',
          current_length: '',
          pen_name: '',
          last_updated: '',
          device_id: '',
          device_name: '',
          device_battery_status: '',
        },
      ],
    },
  },
}
</script>
