<template>
<div>
  <raw-milk-add-new
  :is-add-new-raw-milk-sidebar-active.sync="isAddNewRawMilkSidebarActive"
  @refetch-data="refetchData"
  />

  <raw-milk-edit 
   :is-edit-raw-milk-sidebar-active.sync="isEditRawMilkSidebarActive" 
   :raw-milk-data="rawMilkEdit"
   :milk-condition-options="milkConditionOptions"
   @refetch-data="refetchData"
   />

    <b-card
    no-body
    :title="title"
    >
    
    <b-table
      :fields="tableColumns"
      :items="fetchRawMilkHistory"
      ref="refRawMilkHistoryTable"
      responsive
      primary-key="id"
      class="mb-0"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(no)="data">
        {{ data.index + 1  }}
      </template>

      <template #cell(aksi)="data">
        <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
            </template>
              <b-dropdown-item @click="handleEditData(data.item); isEditRawMilkSidebarActive = !isEditRawMilkSidebarActive">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50" >Delete</span>
              </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</div>
</template>

<script>
import { BCard, BTable, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { useToast } from 'vue-toastification'
const RawMilkEdit = () => import('@/views/pages/raw_milks/detail/RawMilkEdit')

import useRawMilksList from './useRawMilksList'
import rawMilkStoreModule from '../rawMilkStoreModule'
import RawMilkAddNew from './RawMilkAddNew.vue'


import { ref, onUnmounted, onMounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'

import axios from '@axios'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    RawMilkEdit,

    useRawMilksList,
    rawMilkStoreModule,
    RawMilkAddNew
  },
  props: {
    title: {
      type: String,
      default: 'History Perolehan Susu',
    },
  },
  setup(props){

    const RAW_MILK_APP_STORE_MODULE_NAME = 'app-raw-milks'

    // Register module
    if (!store.hasModule(RAW_MILK_APP_STORE_MODULE_NAME)) store.registerModule(RAW_MILK_APP_STORE_MODULE_NAME, rawMilkStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RAW_MILK_APP_STORE_MODULE_NAME)) store.unregisterModule(RAW_MILK_APP_STORE_MODULE_NAME)
    })

    const {
      fetchRawMilkHistory,
      tableColumns,
      perPage,
      currentPage,
      totalRawMilks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRawMilkHistoryTable,

      refetchData,
    } = useRawMilksList()

    const milkConditionOptions = ref([])

    const getMilkConditionOptions = async () => {
        await axios.get('/milk/conditions').then((response) => {
          const {data} = response.data
          data.forEach((data) => {
            milkConditionOptions.value.push({
              value: data.id,
              label: data.name,
            })
          })
        }).catch(error => {
          //show toast error
        })
    }

    getMilkConditionOptions();

    const isEditRawMilkSidebarActive = ref(false);

    const handleDelete = async (id) => {
      await axios.delete(`milk/raw/${id}`)
      .then((response) => {
        if(response.data.status >= 200){
          toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil menghapus data susu mentah',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
        refetchData()
      })
      .catch((error) => {})
    }

    const blankRawMilk = {
      id: null,
      volume: null,
      date_milked: null,
      time_milked: null,
      description: '',
      milk_condition: '',
      milk_condition_id: null,
    }
    
    const rawMilkEdit = ref(JSON.parse(JSON.stringify(blankRawMilk)))

    const handleEditData = (rawMilkData) => {
      rawMilkEdit.value.id = rawMilkData.id;
      rawMilkEdit.value.volume = parseFloat(rawMilkData.volume);
      rawMilkEdit.value.date_milked = rawMilkData.tanggal_perah;
      rawMilkEdit.value.time_milked = rawMilkData.jam_perah;
      rawMilkEdit.value.description = rawMilkData.deskripsi;
      milkConditionOptions.value.forEach((option) => {
        if (option.label == rawMilkData.kondisi_susu) {
          rawMilkEdit.value.milk_condition_id = option.value;
          rawMilkEdit.value.milk_condition = option;
        }
      })
    }

    const isAddNewRawMilkSidebarActive = ref(false)

    
    return {
      isAddNewRawMilkSidebarActive,

      fetchRawMilkHistory,
      tableColumns,
      perPage,
      currentPage,
      totalRawMilks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRawMilkHistoryTable,

      refetchData,
      // 
      milkConditionOptions,

      handleDelete,
      handleEditData,
      rawMilkEdit,
      isEditRawMilkSidebarActive,
    }
  },
}
</script>